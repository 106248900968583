import { useState, useMemo, useEffect, FC } from "react";
import {
  Page,
  Layout,
  Card,
  Button,
  Stack,
  Banner,
  TextField,
  Spinner,
  TextContainer,
  IconSource,
} from "@shopify/polaris";
import { ExternalSmallIcon } from "@shopify/polaris-icons";
import copy from "copy-to-clipboard";
import { Flex } from "@storyofams/react-ui";
import { FlowContainerStatus, FlowNodeType } from "~/graphql/sdk";
import { IPublishErrors, useFlow } from "~/hooks";
import { useShopOrigin } from "~/hooks";
import { useBillingWrapper, hasPaidFeatureAccess } from "~/lib";
import useCopied from "~/hooks/useCopied";
import CopiedBadge from "~/components/CopiedBadge";
import PublishErrorBanner from "~/components/ErrorBanner/PublishErrorBanner";
import styled from "styled-components";

/* 
1. Check for errors, prevent publishing until the errors are resolved
2. After publishing, show options 
*/
interface IPublishPageProps {
  publishErrorInfo: IPublishErrors;
}

export const PublishPage: FC<IPublishPageProps> = ({ publishErrorInfo }) => {
  const { data, publishFlow } = useFlow();
  const shopOrigin = useShopOrigin();
  const billingWrapper = useBillingWrapper();
  const paidFeatureAccess = hasPaidFeatureAccess();

  const {
    publishError,
    setPublishError,
    errorsLength,
    isLoading,
    setIsLoading,
  } = publishErrorInfo;
  const [isPublished, setIsPublished] = useState(false);

  const flowLink = useMemo(
    () =>
      `https://${shopOrigin}/tools/${process.env.REACT_APP_SHOPIFY_APP_NAME}/${data?.slug}`,
    [shopOrigin, data]
  );
  const quizSubUrl = useMemo(
    () => `tools/${process.env.REACT_APP_SHOPIFY_APP_NAME}/${data?.slug}`,
    [data]
  );
  const quizPopupUrl = useMemo(
    () =>
      `#open-quiz-tools/${process.env.REACT_APP_SHOPIFY_APP_NAME}/${data?.slug}`,
    [data]
  );
  const openThemeEditorUrl = useMemo(
    () => `https://${shopOrigin}/admin/themes/current/editor`,
    [shopOrigin, data]
  );

  const { active: isCopiedUrl, activate: activateCopiedUrl } = useCopied();
  const { active: isCopiedKey, activate: activateCopiedKey } = useCopied();
  const { active: isCopiedPopup, activate: activateCopiedPopup } = useCopied();

  const articleStandalone =
    "https://www.trylantern.com/docs/l/link-your-quiz-to-a-dedicated-page";
  const articleEmbed =
    "https://www.trylantern.com/docs/l/embed-the-quiz-in-any-store-page";
  const articlePopup =
    "https://www.trylantern.com/docs/l/how-to-open-the-quiz-in-a-popup";

  const videoPopup = "https://www.youtube.com/watch?v=jhXhN9Ti2Zk";
  const videoEmbed = "https://www.youtube.com/watch?v=6CAjRJ1Ly5c";
  const videoStandalone = "https://www.youtube.com/watch?v=Z0cKOx6Uk_4";

  const republishing = data?.status === FlowContainerStatus.Published;

  useEffect(() => {
    const draftFlow = data?.flows?.[0];

    if (!draftFlow) {
      setIsPublished(true);
      return;
    }

    if (draftFlow.createdAt !== draftFlow.updatedAt) {
      setIsPublished(false);
      return;
    }

    for (let i = 0; i < draftFlow.nodes.length; i += 1) {
      const { createdAt, updatedAt } = draftFlow.nodes[i];

      if (createdAt !== updatedAt) {
        setIsPublished(false);
        return;
      }
    }

    setIsPublished(true);
    return;
  }, [data?.flows?.[0]]);

  useEffect(() => {
    //Checks to see if there are any errors that need to be addressed
    publish({ validateOnly: true });
  }, [data]);

  const publish = async ({ validateOnly }) => {
    setIsLoading(true);
    try {
      const success = await publishFlow({ validateOnly: validateOnly });
      if (success && !validateOnly) {
        setIsPublished(true);
        window?.top?.frames?.["app-iframe"]?.postMessage(
          {
            action: "toast",
            content: "Quiz published successfully",
          },
          "*"
        );
      }
      setPublishError(null);
    } catch (e: any) {
      setPublishError(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ marginBottom: "32px" }}>
      <Page title="Publish">
        <Layout>
          <Layout.Section>
            {!!isLoading && !isPublished && (
              <Flex justifyContent="center" alignItems="center">
                <Spinner />
              </Flex>
            )}
            {!isLoading && !!errorsLength && (
              <PublishErrorBanner
                publishError={publishError}
                errorsLength={errorsLength}
                hasWelcomePage={
                  data?.flows[0].nodes?.[0]?.type === FlowNodeType.Welcome
                }
              />
            )}
            {!isLoading && !errorsLength && isPublished && (
              <>
                <p style={{ color: "#6D7175" }}>
                  Your quiz is ready. Please use one of the methods below to
                  publish it to your storefront.
                </p>
                <br></br>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "stretch",
                    gap: "8px",
                  }}
                >
                  <CardContainer>
                    <Card title="Link to a standalone page" sectioned>
                      <Card.Section>
                        <Stack vertical spacing="tight">
                          <img
                            src={`${process.env.PUBLIC_URL}/illustrations/lantern-publish-1.png`}
                            style={{ width: "100%", margin: "0 auto" }}
                          />
                          <Stack distribution="fill" alignment="fill" vertical>
                            <TextField
                              value={flowLink}
                              label=""
                              disabled
                              autoComplete="off"
                            />

                            <CopiedBadge show={isCopiedUrl}>
                              <Button
                                fullWidth
                                onClick={() => {
                                  copy(flowLink);
                                  activateCopiedUrl();
                                }}
                              >
                                Copy Quiz URL
                              </Button>
                            </CopiedBadge>
                          </Stack>
                          <br></br>
                          <Stack.Item fill>
                            <TextContainer>
                              <p>1. Copy the quiz link above.</p>
                              <p>
                                2. Go to the Theme Editor, and link to the quiz
                                from any link, button, or menu item of your
                                choice.
                              </p>
                              <p>
                                3. Clicking the button, menu item or link will
                                open the quiz in a standalone page.
                              </p>
                            </TextContainer>
                          </Stack.Item>
                          <br></br>
                          <Stack vertical alignment="center">
                            <Button
                              primary
                              onClick={() => window.open(flowLink)}
                              external
                            >
                              Open the quiz in a new tab
                            </Button>
                            <Button
                              onClick={() =>
                                window.open(articleStandalone, "_blank")
                              }
                              icon={ExternalSmallIcon as IconSource}
                              plain
                            >
                              Read a step-by-step guide
                            </Button>

                            <Button
                              onClick={() =>
                                window.open(videoStandalone, "_blank")
                              }
                              icon={ExternalSmallIcon as IconSource}
                              plain
                            >
                              Watch a short video tutorial
                            </Button>
                          </Stack>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </CardContainer>
                  <CardContainer>
                    <Card title="Embed in your store" sectioned>
                      <Card.Section>
                        <Stack vertical spacing="tight">
                          <img
                            src={`${process.env.PUBLIC_URL}/illustrations/lantern-publish-2.png`}
                            style={{ width: "100%", margin: "0 auto" }}
                          />
                          <Stack distribution="fill" alignment="fill" vertical>
                            <TextField
                              value={
                                paidFeatureAccess
                                  ? quizSubUrl
                                  : "Upgrade to access this feature"
                              }
                              label=""
                              disabled
                              autoComplete="off"
                            />

                            <CopiedBadge show={isCopiedKey}>
                              <Button
                                fullWidth
                                onClick={() => {
                                  billingWrapper(() => {
                                    copy(quizSubUrl);
                                    activateCopiedKey();
                                  })();
                                }}
                              >
                                {paidFeatureAccess
                                  ? "Copy Key"
                                  : "Upgrade to unlock"}
                              </Button>
                            </CopiedBadge>
                          </Stack>
                          <br></br>
                          <Stack.Item fill>
                            <TextContainer>
                              <p>1. Copy the quiz key above.</p>
                              <p>
                                2. Go to the Theme Editor, and add the{" "}
                                <b>Lantern Quiz</b> block to the page section
                                where you want to display the quiz.
                              </p>
                              <p>
                                3. Paste the quiz key in the <b>Quiz Sub-URL</b>{" "}
                                setting.
                              </p>
                            </TextContainer>
                          </Stack.Item>
                          <br></br>
                          <Stack vertical alignment="center">
                            <Button
                              primary
                              onClick={billingWrapper(() =>
                                window.open(openThemeEditorUrl, "_blank")
                              )}
                            >
                              {paidFeatureAccess
                                ? "Go to the Theme Editor"
                                : "Upgrade to unlock"}
                            </Button>
                            <Button
                              onClick={billingWrapper(() =>
                                window.open(articleEmbed, "_blank")
                              )}
                              icon={ExternalSmallIcon as IconSource}
                              plain
                            >
                              Read a step-by-step guide
                            </Button>
                            <Button
                              onClick={billingWrapper(() =>
                                window.open(videoEmbed, "_blank")
                              )}
                              icon={ExternalSmallIcon as IconSource}
                              plain
                            >
                              Watch a short video tutorial
                            </Button>
                          </Stack>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </CardContainer>
                  <CardContainer>
                    <Card title="Open in a popup" sectioned>
                      <Card.Section>
                        <Stack vertical spacing="tight">
                          <img
                            src={`${process.env.PUBLIC_URL}/illustrations/lantern-publish-3.png`}
                            style={{ width: "100%", margin: "0 auto" }}
                          />
                          <Stack distribution="fill" alignment="fill" vertical>
                            <TextField
                              value={
                                paidFeatureAccess
                                  ? quizPopupUrl
                                  : "Upgrade to access this feature"
                              }
                              label=""
                              disabled
                              autoComplete="off"
                            />

                            <CopiedBadge show={isCopiedPopup}>
                              <Button
                                fullWidth
                                onClick={() => {
                                  billingWrapper(() => {
                                    copy(quizPopupUrl);
                                    activateCopiedPopup();
                                  })();
                                }}
                              >
                                {paidFeatureAccess
                                  ? "Copy Popup URL"
                                  : "Upgrade to unlock"}
                              </Button>
                            </CopiedBadge>
                          </Stack>
                          <br></br>
                          <Stack.Item fill>
                            <TextContainer>
                              <p>1. Copy the popup URL above.</p>
                              <p>
                                2. Go to the Theme Editor, and activate{" "}
                                <b>Lantern Quiz Popup</b> under App Embeds.
                              </p>
                              <p>
                                3. Add the popup URL to the link or button of
                                your choice to trigger the popup.
                              </p>
                            </TextContainer>
                          </Stack.Item>
                          <br></br>
                          <Stack vertical alignment="center">
                            <Button
                              fullWidth
                              primary
                              onClick={billingWrapper(() =>
                                window.open(openThemeEditorUrl, "_blank")
                              )}
                            >
                              {paidFeatureAccess
                                ? "Go to the Theme Editor"
                                : "Upgrade to unlock"}
                            </Button>
                            <Button
                              onClick={billingWrapper(() =>
                                window.open(articlePopup, "_blank")
                              )}
                              icon={ExternalSmallIcon as IconSource}
                              plain
                            >
                              Read a step-by-step guide
                            </Button>
                            <Button
                              onClick={billingWrapper(() =>
                                window.open(videoPopup, "_blank")
                              )}
                              icon={ExternalSmallIcon as IconSource}
                              plain
                            >
                              Watch a short video tutorial
                            </Button>
                          </Stack>
                        </Stack>
                      </Card.Section>
                    </Card>
                  </CardContainer>
                </div>
              </>
            )}
            {!isLoading && !errorsLength && !isPublished && (
              <Banner
                title={`Your quiz is ready to ${
                  republishing ? "republish" : "publish"
                }!`}
                status="success"
                action={{
                  content: republishing ? "Republish" : "Publish",
                  onAction: () => {
                    if (window?.analytics) {
                      window.analytics.track("Flow Builder - Publish Button");
                    }
                    publish({ validateOnly: false });
                  },
                }}
              >
                <p>
                  Click on the Publish button below to get more options on where
                  to publish your quiz.
                </p>
              </Banner>
            )}
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  );
};

const CardContainer = styled.div`
  flex: 1 1 0;
  min-width: 240px;

  & > .Polaris-Card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
`;
